import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import loadable from "@loadable/component";
import { ChakraProvider } from "@chakra-ui/core";
import axios from "axios";

import { theme } from "./theme";
import config from "./config";
import { Campaign, LandingPageSetting, ProductCategory } from "./types";
import dayjs from "dayjs";
import unionBy from "lodash/unionBy";

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

type CampaignState = {
  data?: Campaign;
  isFetching: boolean;
  isFailed: boolean;
  is404: boolean;
};

type SettingState = {
  data?: LandingPageSetting;
  isFetching: boolean;
};

export type AppContextValue = {
  // isOpenModalRegister: boolean;
  // setOpenModalRegister: (s: boolean) => void;
  campaign: CampaignState;
  setting: SettingState;
  // products: ProductsState;
  // resellers: ResellersState;
  // stores: StoreState;
  getCampaign: () => void;
  getSetting: () => void;

  // getProducts: () => void;
  // getResellers: () => void;
  // getStores: () => void;
};

export const AppContext = React.createContext<AppContextValue>({} as any);

const LandingScene = loadable(() => import("./scenes/Landing"));
const CampaignScene = loadable(() => import("./scenes/Campaign"));
const ProductsScene = loadable(() => import("./scenes/Products"));
const MembershipScene = loadable(() => import("./scenes/Membership"));
const NotFoundScene = loadable(() => import("./scenes/NotFound"));

function App() {
  // const [isOpenModalRegister, setOpenModalRegister] = React.useState(false);
  // const [
  //   isOpenModalRegisterSuccess,
  //   setOpenModalRegisterSuccess,
  // ] = React.useState(false);

  const [campaign, setCampaign] = React.useState<CampaignState>({
    is404: false,
    isFailed: false,
    isFetching: false,
  });

  const [setting, setSetting] = React.useState<SettingState>({
    isFetching: false,
  });

  const handleGetCampaign = React.useCallback(async () => {
    try {
      setCampaign((cur) => {
        return {
          ...cur,
          isFetching: true,
          isFailed: false,
        };
      });

      const { data } = await axios.get<Campaign[]>(`/campaign/mx`);
      const activeCampaign = data.find((f, i) => {
        const startDate = dayjs(f.startDate).add(-1, "day");
        const endDate = dayjs(f.endDate).add(1, "day");
        const isAfter = dayjs().isAfter(startDate, "day");
        const isBefore = dayjs().isBefore(endDate, "day");

        return isAfter && isBefore;
      });

      if (activeCampaign) {
        // extract categories
        const pCategories: ProductCategory[] = [];
        activeCampaign.Products.forEach((p) => {
          pCategories.push(p.ProductCategory);
        });
        activeCampaign.categories = unionBy(pCategories, "id");
      }

      setCampaign((cur) => {
        return {
          data: activeCampaign,
          is404: !activeCampaign,
          isFetching: false,
          isFailed: false,
        };
      });
    } catch (e) {
      setCampaign((cur) => {
        return {
          is404: true,
          isFetching: false,
          isFailed: true,
        };
      });
    }
  }, []);

  const handleGetSetting = React.useCallback(async () => {
    try {
      setSetting((cur) => {
        return {
          ...cur,
          isFetching: true,
        };
      });

      const { data } = await axios.get<LandingPageSetting>(`/landingPage/mx`);

      setSetting({
        data,
        isFetching: false,
      });
    } catch (e) {
      setSetting((cur) => {
        return {
          isFetching: false,
        };
      });
    }
  }, []);

  // const handleSubmit = (
  //   values: RegisterProductForm,
  //   cb: (error: string) => void
  // ) => {
  //   submitRegister(values)
  //     .then(() => {
  //       cb("");
  //       setOpenModalRegisterSuccess(true);
  //       setOpenModalRegister(false);
  //     })
  //     .catch((e) => {
  //       if (e.isAxiosError) {
  //         const err: AxiosError = e as AxiosError;
  //         if (err.response?.status && err.response.status === 400) {
  //           cb(err.response.data);
  //           return;
  //         }
  //       }
  //       cb(e.toString());
  //     });
  // };

  // React.useEffect(() => {
  //   if (isOpenModalRegister) {
  //     handleGetProducts();
  //     handleGetReseller();
  //   }
  // }, [isOpenModalRegister, handleGetProducts, handleGetReseller]);

  React.useEffect(() => {
    handleGetCampaign();
    handleGetSetting();
  }, []);

  return (
    <ChakraProvider theme={theme} resetCSS={true}>
      <AppContext.Provider
        value={{
          // isOpenModalRegister,
          // setOpenModalRegister,
          campaign,
          setting,
          // products,
          // resellers,
          // stores,
          getCampaign: handleGetCampaign,
          getSetting: handleGetSetting,
          // getResellers: handleGetReseller,
          // getStores: handleGetStores,
        }}
      >
        <Router>
          <Switch>
            <Route path={config.paths.landing} exact={true}>
              <LandingScene />
            </Route>
            <Route path={config.paths.campaign} exact={true}>
              <CampaignScene />
            </Route>
            <Route path={config.paths.products} exact={true}>
              <ProductsScene />
            </Route>
            <Route path={config.paths.membership} exact={true}>
              <MembershipScene />
            </Route>
            <Route>
              <NotFoundScene />
            </Route>
          </Switch>
          {/* <ModalRegisterProduct
            onSubmit={handleSubmit}
            resellers={resellers.data}
            isFetchingResellers={resellers.isFetching}
            isFetchingProducts={products.isFetching}
            products={products.data}
            isOpen={isOpenModalRegister}
            onClose={() => {
              setOpenModalRegister(false);
            }}
          /> */}
          {/* <ModalRegisterSuccess
            isOpen={isOpenModalRegisterSuccess}
            onClose={() => {
              setOpenModalRegisterSuccess(false);
            }}
          /> */}
        </Router>
      </AppContext.Provider>
    </ChakraProvider>
  );
}

export default App;
