const envs = {
  apiHost: process.env.REACT_APP_API_HOST,
};

const config = {
  envs,
  paths: {
    landing: "/",
    products: "/products",
    campaign: "/campaign",
    membership: "/resellers",
  },
  apis: {
    getCampaign: `${envs.apiHost}/campaign/current`,
    getProducts: `${envs.apiHost}/product`,
    getStores: `${envs.apiHost}/store`,
    getResellers: `${envs.apiHost}/reseller`,
    getUploadUrl: (ext: string) => {
      return `${envs.apiHost}/receipt/uploadUrl?fileExtension=${ext}`;
    },
    register: `${envs.apiHost}/member/register`,
    luckyDraw: (isMember: boolean) => {
      return `${envs.apiHost}/contest/participate${
        isMember ? "?isMember=true" : ""
      }`;
    },
  },
};

export default config;
