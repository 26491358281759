import { theme as defaultTheme } from "@chakra-ui/theme";
import { merge } from "@chakra-ui/utils";

const breakpoints: any = ["30em", "48em", "62em", "80em", "100em", "118.75em"];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];
breakpoints.sxl = breakpoints[4];
breakpoints.mxl = breakpoints[5];

const theme = merge(defaultTheme, {
  breakpoints,
  textStyles: {
    navCategory: {
      fontWeight: "700",
      fontSize: {
        base: "28px",
      },
      lineHeight: {
        base: "48px",
      },
    },
    luckyTitle: {
      fontWeight: "700",
      fontSize: {
        base: "24px",
        lg: "40px",
      },
      lineHeight: {
        base: "110%",
      },
    },
    campaignTitle: {
      fontWeight: "700",
      fontSize: {
        base: "28px",
        lg: "72px",
      },
      lineHeight: {
        base: "100%",
      },
    },
    campaignDesc: {
      fontSize: {
        base: "16px",
        lg: "24px",
      },
      lineHeight: {
        base: "100%",
      },
    },
  },
  styles: {
    global: {
      body: {
        fontFamily: `'BrownPro', sans-serif`,
        color: "text",
      },
    },
  },
  colors: {
    white: "#fff",
    text: "#222222",
    primary: "#00EAD0",
    modalBg: "#2F3132",
    modalText: "#fff",
    negative: "#FF164E",
    placeholder: "rgba(255, 255, 255, 0.7)",
  },
  zIndices: {
    headroom: 1100,
    drawer: 1350,
    navExpand: 1400,
    modalContent: 1550,
    modalOverlay: 1500,
  },
  components: {
    Select: {
      variants: {
        borderless: {
          field: {
            lineHeight: "16px",
            height: "32px",
            "&:invalid": { color: "placeholder" },
          },
        },
      },
      sizes: {
        md: {
          field: {
            height: "32px",
          },
        },
      },
    },
    Input: {
      variants: {
        borderless: {
          field: {
            color: "white",
            background: "transparent",
            border: "none",
            borderRadius: "0",
            padding: "8px",
            fontSize: "16px",
            borderBottom: "1px solid white",
            _placeholder: {
              color: "placeholder",
            },
            _invalid: {
              color: "placeholder",
            },
            _disabled: {
              cursor: "not-allowed",
            },
          },
        },
      },
      sizes: {
        md: {
          field: {
            height: "32px",
          },
        },
      },
      defaultProps: {
        variant: "borderless",
      },
    },
    Modal: {
      baseStyle: {
        overlay: {
          width: "100vw",
          height: "100vh",
          position: "fixed",
          left: "0px",
          top: "0px",
          background: "rgba(0, 0, 0, 0.3)",
          display: "flex",
          zIndex: "modalOverlay",
          justifyContent: "center",
          alignItems: "flex-start",
          overflow: "auto",
        },
        content: {
          display: "flex",
          flexDirection: "column",
          position: "relative",
          width: "100%",
          outline: "currentcolor none 0px",
          borderRadius: "0.25rem",
          background: "modalBg",
          color: "white",
          marginTop: "3.75rem",
          marginBottom: "3.75rem",
          zIndex: "modalContent",
          maxWidth: "28rem",
          overflow: "auto",
        },
      },
      sizes: {
        full: {
          content: {
            marginTop: 0,
            marginBottom: 0,
          },
        },
        register: {
          content: {
            padding: {
              base: "28px 16px 28px 16px",
              md: "80px 100px 48px 100px",
            },
            maxWidth: { base: "100%", md: "720px" },
            width: "100%",
            mt: { base: 0, md: "50px" },
          },
        },
        tnc: {
          header: { fontSize: 25, padding: "15px 20px" },
          content: {
            maxWidth: { base: "100%", md: "1024px" },
          },
          body: {
            overflow: "auto",
            maxHeight: "80vh",
            padding: {
              base: "15px",
              md: "20px",
            },
            width: "100%",

            a: {
              color: "rgb(0, 234, 208)",
              textDecoration: "underline",
            },
          },
        },
      },
      defaultProps: {
        size: "register",
      },
    },
    Drawer: {
      variants: {
        main: {
          overlay: {
            zIndex: "drawer",
          },
        },
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "bold",
        background: "white",
        color: "text",
        borderRadius: 0,
      },
      sizes: {
        sm: {
          fontSize: "14px",
          lineHeight: "14px",
          height: "28px",
        },
        md: {
          fontSize: "14px",
          lineHeight: "14px",
          height: "40px",
        },
        lg: {
          fontSize: "14px",
          lineHeight: "14px",
          height: "56px",
        },
      },
      variants: {
        dark: {
          color: "primary",
          backgroundColor: "#2F3132",
        },
        darkHeader: {
          color: "white",
          backgroundColor: "#222222",
        },
        selected: {
          backgroundColor: "primary",
        },
        icon: {
          padding: "0.25em",
          width: "1.25em",
          height: "1.25em",
          fontSize: "24px",
          color: "inherit",
          background: "transparent",
          borderRadius: "50%",
          minWidth: "initial",
          _hover: {
            background: "#ccc",
          },
          _focus: {
            background: "#ccc",
          },
        },
      },
      defaultProps: {
        size: "sm",
      },
    },
  },
});

export { theme };
